export const ADMIN_DASHBOARD_DATA = 'ADMIN_DASHBOARD_DATA';
export const GET_UNIQUE_DEALNAMES = 'GET_UNIQUE_DEALNAMES';
export const GET_ALL_PROCESSOR = 'GET_ALL_PROCESSOR';
export const GET_ALL_FIELDS = "GET_ALL_FIELDS";
export const GET_ALL_ISSUECERTIFICATE_DATA = "GET_ALL_ISSUECERTIFICATE_DATA";
export const GET_ALL_MAP_PROCESSOR = "GET_ALL_MAP_PROCESSOR";
export const GET_ALL_ATTRIBUTES = "GET_ALL_ATTRIBUTES"
export const GET_ADDITIONAL_DATA = "GET_ADDITIONAL_DATA";
export const SAVE_MAP_FIELDS = "SAVE_MAP_FIELDS";
export const ADMIN_PROCESSOR_DASHBOARD_DATA = 'ADMIN_PROCESSOR_DASHBOARD_DATA';
export const ADD_DEAL = "ADD_DEAL";
export const ADD_PROCESSOR = "ADD_PROCESSOR";
