export const PROCESSOR_DASHBOARD_DATA = 'PROCESSOR_DASHBOARD_DATA';
export const GET_FILE_LIST = "GET_FILE_LIST";
export const UPLOAD_CONTRACT = "UPLOAD_CONTRACT";
export const UPLOAD_LMS = "UPLOAD_LMS";
export const SAVE_LMS = "SAVE_LMS";
export const VIEW_LOANS = "VIEW_LOANS";
export const GET_ATTRIBUTE_DETAIL = "GET_ATTRIBUTE_DETAIL";
export const REVIEW = "REVIEW";
export const EDIT_LOANS="EDIT_LOANS";
export const DDREPORT="DDREPORT";
export const GET_ALL_MESSAGE="GET_ALL_MESSAGE"

